<template>
  <div class="timeline">
    <div
      v-for="item of list"
      :key="item.index"
      class="item">
      <div class="title">
        {{ item.index }}
      </div>
      <div class="image">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  methods: {
    initialize () {
      this.list = new Array(10).fill(0).map((item, index) => {
        return {
          index,
          value: index + ' hello world'
        }
      })
    }
  },
  created () {
    this.initialize()
  }
}
</script>

<style scoped lang="scss">
.timeline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
}
.timeline .item {
  width: 200px;
  height: 500px;
  position: relative;
  border: 1px solid #ccc;
}
.item:nth-child(even) .title {
  position: absolute;
  top: 350px;
  width: 100%;
  background-color: rgba(255, 0, 0, .3);
}
.item:nth-child(even) .image {
  position: absolute;
  top: 450px;
  width: 100%;
  background-color: rgba(0, 255, 0, .3);
}
.item:nth-child(odd) .title {
  position: absolute;
  top: 150px;
  width: 100%;
  background-color: rgba(255, 0, 0, .3);
}
.item:nth-child(odd) .image {
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: rgba(0, 255, 0, .3);
}

</style>
